<template>
    <div>
        <div class="d-flex h-vh-100 p-3">
            <div class="w-50 border-end p-3">
                <h6>헤더 스타일</h6>
                <ul class="p-3">
                    <li class="mb-3">
                        <b>햄버거바 위치</b>
                        <div class="form-check">
                          <input v-model="selectedHamburgerBar" class="form-check-input" type="radio" name="flexRadioDefault" id="hamburger_bar_none" value="none" checked>
                          <label class="form-check-label" for="hamburger_bar_none">
                            none <small class="text-danger opacity-75">*하단 네비게이션에 메뉴 바로가기가 있다면 none 권장</small>
                          </label>
                        </div>
                        <div class="form-check">
                          <input v-model="selectedHamburgerBar" class="form-check-input" type="radio" name="flexRadioDefault" id="hamburger_bar_top_left" value="top-left">
                          <label class="form-check-label" for="hamburger_bar_top_left">
                            top-left
                          </label>
                        </div>
                        <div class="form-check">
                          <input v-model="selectedHamburgerBar" class="form-check-input" type="radio" name="flexRadioDefault" id="hamburger_bar_top_right" value="top-right">
                          <label class="form-check-label" for="hamburger_bar_top_right">
                            top-right
                          </label>
                        </div>
                    </li>
                    <li class="mb-3">
                        <b>헤더 로고 표시</b>
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" id="header_logo" v-model="header_logo" @click="ChgHeaderLogo()">
                            <label class="form-check-label" for="header_logo">표시 여부 on/off</label>
                        </div>
                        <div class="form-group bg-light rounded p-3 mt-3">
                            <label class="form-check-label d-block mb-3 text-secondary fw-bold">로고 이미지 변경</label>
                            <input class="form-controller" type="file" id="header_logo" accept="image/*" @change="ChgLogoImg($event)">
                        </div>
                    </li>
                </ul>
                <hr>
                <ul class="p-3">
                    <li class="mb-3">
                        <b>테마 색상</b>
                        <div class="d-flex">
                            <input type="color" name="color" id="color_pallete" list="list" :value="$store.state.theme_color" @change="ChgThemeColor">
                            <datalist id="list">
                                <option v-for="(item,index) in theme_color_list" :key="index">{{item}}</option>
                            </datalist>
                            <label for="color_pallete"></label>
                        </div>
                    </li>
                    <li class="mb-3">
                        <b>상단 스타일</b>
                        <div class="form-check">
                            <input class="form-check-input" v-model="main_style" type="radio" name="top_main" id="top_main_1" checked value="total_assets">
                            <label class="form-check-label" for="top_main_1">
                                통합 자산 표시
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" v-model="main_style" type="radio" name="top_main" id="top_main_2" value="logo_main">
                            <label class="form-check-label" for="top_main_2">
                                로고 표시<small class="text-danger opacity-75"> *색상 있는 로고 사용 권장</small>
                            </label>
                        </div>
                    </li>
                    <li class="mb-3">
                        <b>상단 배너</b>
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" id="top_banner" v-model="top_banner" @click="ChgTopBanner">
                            <label class="form-check-label" for="top_banner">표시 여부 on/off</label>
                        </div>
                    </li>
                    <li class="mb-3">
                        <b>공지사항 레이아웃</b>
                        <div class="form-check">
                            <input class="form-check-input" v-model="notice_type" type="radio" name="notice_type" id="notice_type_1" value="simple">
                            <label class="form-check-label" for="notice_type_1">
                                기본 타입
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" v-model="notice_type" type="radio" name="notice_type" id="notice_type_2" checked value="color">
                            <label class="form-check-label" for="notice_type_2">
                                색상 타입
                            </label>
                        </div>
                    </li>
                    
                    <li class="mb-3">
                        <b>하단 네비게이션</b>
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" id="nav_1" v-model="bottom_nav" @click="ChgBottomNav">
                            <label class="form-check-label" for="nav_1">표시 여부 on/off</label>
                        </div>
                    </li>
                </ul>
                <hr>
                <!-- <h6>기능 변경</h6>
                <ul class="p-3">
                    <li class="mb-3">
                        <b>다크모드</b>
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" id="darkmode" v-model="dark_mode" @click="ChgDarkmode">
                            <label class="form-check-label" for="darkmode">on/off</label>
                        </div>
                    </li>
                </ul> -->
            </div>
            <div class="w-50 left_box position-relative h-100">
                <div class="mx-auto">
                    <img src="@/assets/img/Free-iPhone-X-Mockup.png" class="mockup_img position-absolute top-50 start-50 translate-middle">
                    <router-view class="border mobile_box overflow-auto position-absolute top-50 start-50 translate-middle" />
                </div>       
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            top_main:true,
            top_banner:false,
            bottom_nav:false,
            bottom_nav:false,
            theme_color_list: [
                '#4b67f9','#2b3b92','#2345a4','#25b67d','#f44336'
            ],
            selectedHamburgerBar:'top-right',
            header_logo:true,
            main_style:'total_assets',
            notice_type:'color',
            dark_mode:false
        }
    },
    watch: {
        selectedHamburgerBar(newVal) {
            this.$store.commit('updateHamburgerBar', newVal);
        },
        main_style(newVal){
            this.$store.commit('ChgMainStyle', newVal)
        },
        notice_type(newVal){
            this.$store.commit('ChgNoticeType', newVal)
        },
    },
    methods:{
        ChgThemeColor(e){
            this.$store.commit('ChgThemeColor', e.target.value)
        },
        ChgTopBanner(){
            this.$store.commit('ChgTopBanner', !this.top_banner)
        },
        ChgBottomNav(){
            this.$store.commit('ChgBottomNav', !this.bottom_nav)
        },
        ChgHeaderLogo(){
            this.$store.commit('ChgHeaderLogo', !this.header_logo)
        },
        ChgLogoImg(event){
            const file = event.target.files[0];
            this.$store.commit('ChgLogoImg', file)
        },
        ChgDarkmode(){
            this.$store.commit('ChgDarkmode', !this.dark_mode)
        }
    },
}
</script>

<style scoped>
    .h-vh-100{
        height: 100vh;
    }
    .left_box{
        background-color: #ededed;
    }
    .mobile_box{
        width: 360px;
        height: 786px;
        border-radius: 38px;
        background-color: #F5F6FA;
    }
    .mockup_img{
        width: 418px;
        filter: drop-shadow(0 10px 25px rgba(0,0,0,0.15));
    }
</style>