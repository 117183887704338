<template>
  <div class="sidebar">
    <div class="my-5 text-center">
        <i class="far fa-send-backward fa-3x mb-3"></i> <h5>WALLET</h5>
        <p>Layout guide</p>
    </div>
    <p class="px-3">Page</p>
    <ul class="side-list">
        <!-- <li class="p-2 my-3">
            <i class="far fa-user me-3"></i>Login
        </li> -->
        <li class="p-2 my-3 active">
            <i class="far fa-tachometer-slow me-3"></i>Dashboard
        </li>
    </ul>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.text-primary {
    color: #4b67f9;
}
.sidebar{
    background: #3f51b5;
    color: white;
    width: 300px;
    height: 100vh;
    .side-list{
        padding-left: 15px;
        li{
            border-radius: 15px 0 0 15px;
            position: relative;
            display: flex;
            align-items: center;
            i{
            width: 42px;
            height: 42px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffffb6;
            }
        }
        li.active{
            /*&::after{
                position: absolute;
                right: 0;
                top: 0;
                content:'';
                display:block;
                width: 50px;
                height: 100%;
                background: #fff;
            }*/
            background: #fff;
            color: #4b67f9;
            i{
                color: #4b67f9;
            }
        }
    }
}
</style>