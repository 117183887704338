<template>
  <Header>
    
  </Header>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
Header{
    background: red;
height: 55px;
}
</style>