import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/dashboard',
    children:[
      {
        path: '/mobile-home',
        name: 'mobile-home',
        component: () => import(/* webpackChunkName: "wallet" */ '../views/wallet/Home.vue'),
        children: [
          {
            path: '/dashboard',
            name: 'dashboard',
            component: () => import(/* webpackChunkName: "wallet" */ '../views/wallet/Dashboard.vue')
          }
        ]
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
