import { createStore } from 'vuex'

export default createStore({
  state: {
    theme_color: '#4b67f9',
    top_banner: false,
    bottom_nav: false,
    hamburger_bar:'top-right',
    header_logo:true,
    logo_img: '',
    main_style:'total_assets',
    notice_type:'color',
    dark_mode:false
  },
  mutations: {
    ChgThemeColor(state, data){
      state.theme_color = data;
    },
    ChgTopBanner(state, data){
      state.top_banner = data;
    },
    ChgBottomNav(state, data){
      state.bottom_nav = data;
    },
    updateHamburgerBar(state, newHamburgerBar) {
      state.hamburger_bar = newHamburgerBar;
    },
    ChgHeaderLogo(state, data){
      state.header_logo = data;
    },
    ChgLogoImg(state, data){
      const reader = new FileReader();
      reader.onload = (e) => {
          state.logo_img = e.target.result;
      };
      reader.readAsDataURL(data);
    },
    ChgMainStyle(state, data){
      state.main_style = data;
    },
    ChgNoticeType(state, data){
      state.notice_type = data;
    },
    ChgDarkmode(state, data){
      state.dark_mode = data;
    }
  },
  actions: {
  },
  modules: {
  }
})
